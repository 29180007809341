exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-company-js": () => import("./../../../src/pages/about/company.js" /* webpackChunkName: "component---src-pages-about-company-js" */),
  "component---src-pages-about-judges-js": () => import("./../../../src/pages/about/judges.js" /* webpackChunkName: "component---src-pages-about-judges-js" */),
  "component---src-pages-about-past-judges-js": () => import("./../../../src/pages/about/past-judges.js" /* webpackChunkName: "component---src-pages-about-past-judges-js" */),
  "component---src-pages-about-rules-js": () => import("./../../../src/pages/about/rules.js" /* webpackChunkName: "component---src-pages-about-rules-js" */),
  "component---src-pages-about-sponsors-js": () => import("./../../../src/pages/about/sponsors.js" /* webpackChunkName: "component---src-pages-about-sponsors-js" */),
  "component---src-pages-dato-cms-video-title-js": () => import("./../../../src/pages/{DatoCmsVideo.title}.js" /* webpackChunkName: "component---src-pages-dato-cms-video-title-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-submissions-film-library-js": () => import("./../../../src/pages/submissions/film-library.js" /* webpackChunkName: "component---src-pages-submissions-film-library-js" */),
  "component---src-pages-submissions-monthly-festival-js": () => import("./../../../src/pages/submissions/monthly-festival.js" /* webpackChunkName: "component---src-pages-submissions-monthly-festival-js" */),
  "component---src-pages-submissions-what-now-js": () => import("./../../../src/pages/submissions/what-now.js" /* webpackChunkName: "component---src-pages-submissions-what-now-js" */),
  "component---src-pages-watch-film-library-js": () => import("./../../../src/pages/watch/film-library.js" /* webpackChunkName: "component---src-pages-watch-film-library-js" */),
  "component---src-pages-watch-monthly-festival-js": () => import("./../../../src/pages/watch/monthly-festival.js" /* webpackChunkName: "component---src-pages-watch-monthly-festival-js" */),
  "component---src-pages-watch-past-winners-js": () => import("./../../../src/pages/watch/past-winners.js" /* webpackChunkName: "component---src-pages-watch-past-winners-js" */)
}

